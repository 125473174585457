@import 'primeicons/primeicons.css';

* {
    font-family: 'inter', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smootyhing: grayscale;
}

:root {
    // Brand Colors
    --primary: #2563eb !important;
    --primary-50: #eef5ff;

    --dark-blue: #0a2156;

    --white: #fff;

    //Gray Colors
    --gray-50: #f2f5f9;
    --gray-100: #e3e8ef;
    --gray-200: #cdd5e0;
    --gray-300: #97a3b6;
    --gray-400: #687489;
    --gray-500: #64748b;
    --gray-600: #4b5563;
    --gray-700: #334155;
    --gray-800: #1e293b;
    --gray-900: #0f172a;
    --gray-950: #020617;

    //Blue Colors;
    --blue-light: #e5ecfd;

    --evaluation: #417aff1e;
}

/* Text Colors */
.text-primary {
    color: var(--primary) !important;
}

.text-primary-50 {
    color: var(--primary-50) !important;
}

.text-dark-blue {
    color: var(--dark-blue) !important;
}

.text-white {
    color: var(--white) !important;
}

.text-gray-50 {
    color: var(--gray-50) !important;
}

.text-gray-100 {
    color: var(--gray-100) !important;
}

.text-gray-200 {
    color: var(--gray-200) !important;
}

.text-gray-300 {
    color: var(--gray-300) !important;
}

.text-gray-400 {
    color: var(--gray-400) !important;
}

.text-gray-500 {
    color: var(--gray-500) !important;
}

.text-gray-600 {
    color: var(--gray-600) !important;
}

.text-gray-700 {
    color: var(--gray-700) !important;
}

.text-gray-800 {
    color: var(--gray-800) !important;
}

.text-gray-900 {
    color: var(--gray-900) !important;
}

.text-gray-950 {
    color: var(--gray-950) !important;
}

.text-blue-light {
    color: var(--blue-light) !important;
}

/* Background Colors */

.bg-primary-50 {
    background-color: var(--primary-50) !important;
}

.bg-dark-blue {
    background-color: var(--dark-blue) !important;
}

.bg-white {
    background-color: var(--white) !important;
}

.bg-gray-50 {
    background-color: var(--gray-50) !important;
}

.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

.bg-gray-200 {
    background-color: var(--gray-200) !important;
}

.bg-gray-300 {
    background-color: var(--gray-300) !important;
}

.bg-gray-400 {
    background-color: var(--gray-400) !important;
}

.bg-gray-500 {
    background-color: var(--gray-500) !important;
}

.bg-gray-600 {
    background-color: var(--gray-600) !important;
}

.bg-gray-700 {
    background-color: var(--gray-700) !important;
}

.bg-gray-800 {
    background-color: var(--gray-800) !important;
}

.bg-gray-900 {
    background-color: var(--gray-900) !important;
}

.bg-gray-950 {
    background-color: var(--gray-950) !important;
}

.bg-blue-light {
    background-color: var(--blue-light) !important;
}

// Mixins

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-phone-and-tablet-portrait {

    @media (max-width: 599px),
    (max-width: 990px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {

    @media (max-width: 921px),
    (max-width: 1024px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1100px) {
        @content;
    }
}

// Custom text sizes

.text-xs {
    font-size: 0.75rem;
    /* 12px */
    line-height: 1rem;
    /* 16px */
}

.text-md {
    font-size: 0.875rem;
    line-height: 1.2rem;
    /* 16px */
}

.text-base {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
}

.text-xl {
    font-size: 1.25rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
}

.text-2xl {
    font-size: 1.5rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
}

.text-3xl {
    font-size: 1.875rem;
    /* 30px */
    line-height: 2.25rem;
    /* 36px */
}

.text-4xl {
    font-size: 2.25rem;
    /* 36px */
    line-height: 2.5rem;
    /* 40px */
}

.text-5xl {
    font-size: 3rem;
    /* 48px */
    line-height: 1rem;
}

.text-6xl {
    font-size: 4rem;
    /* 64px */
    line-height: 4.5rem;
}

.text-7xl {
    font-size: 5rem;
    /* 80px */
    line-height: 5.5rem;
}

.text-8xl {
    font-size: 6rem;
    /* 96px */
    line-height: 6.5rem;
}

.tracking-tight {
    letter-spacing: -0.025em;
}

.tracking-tighter {
    letter-spacing: -0.05em;
}

// Headings

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.6px;
    font-weight: 600 !important;
}

p {
    font-weight: 400 !important;
}

// Scrollbar

*::-webkit-scrollbar {
    border-radius: 999px;
    width: 8px;
    background-color: var(--gray-200);
}

*::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--gray-400);
}

.loader-container {
    width: 56px;
    height: 56px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 3px solid #a2bfff;

    .loader {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        display: inline-block;
        border-top: 3px solid var(--white);
        border-right: 3px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}

.rotation {
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}

.admin-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: absolute;
    background: rgba(123, 160, 255, 0.2);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
}

.admin-loader .text {
    margin-top: -70px !important;
}

.blured {
    filter: blur(8px);
}

@keyframes animate {
    0% {
        background-position: 0% 100%;
    }

    50% {
        background-position: 100% 0%;
    }

    100% {
        background-position: 0% 100%;
    }
}

.input {
    background-color: rgb(244, 242, 255);
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    height: calc(1.875rem + 2px);
    width: 50%;
    border-radius: 0.25rem;
    padding: 0.75rem;

    i {
        font-size: 1.2rem;
    }

    input {
        width: 85%;
        border: 0 none;
        background-color: rgb(244, 242, 255);
        font-size: 0.75rem;

        &:focus {
            outline: none;
            background: var(--white);
        }
    }

    &:focus-within {
        outline: 1.7px solid var(--primary);
        background: var(--white);
    }
}

textarea {
    transition: box-shadow 0.2s;
    color: var(--dark-blue);
    font-weight: 500;
    padding: 0.5rem 0.5rem;
    height: 7rem;
    border-radius: 0.25rem;
    border: 1px solid #cad1d7;
    font-size: 0.875rem !important;

    &:hover {
        border-color: #2196f3;
    }

    &::placeholder {
        color: var(--gray-500);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px var(--primary);
    }
}

.table-sefin {
    width: 100%;
    overflow-x: scroll;

    th,
    td {
        padding: 1rem;
        font-size: 0.75rem !important;

        border-top: 1px solid rgb(217, 213, 236);
        border-bottom: 1px solid rgb(217, 213, 236);
    }

    thead {
        width: 100%;
        color: #0a2156;
        background-color: rgb(244, 242, 255);
    }

    td {
        background-color: #fff;
    }

    tbody {
        color: black;
        font-size: 13px;
        font-weight: 600;
    }
}

.table-flush {
    th {
        font-size: 0.75rem !important;
        font-weight: 600;
        background: var(--gray-50);
        text-transform: capitalize !important;
        color: var(--dark-blue);
    }

    td {
        background: var(--white);
    }
}

.table-wrap {

    td,
    th {
        white-space: wrap !important;
    }
}

.user-data {
    font-size: 0.75rem;
    color: var(--gray-5);
}

.btn-warning {
    background-color: #eab308 !important;
    border: none !important;
}

.btn-secondary {
    // box-shadow: none !important;
    background-color: var(--blue-light) !important;
    color: var(--dark-blue) !important;
    border: none !important;
}

.form-control {
    transition: box-shadow 0.2s;
    color: var(--dark-blue) !important;
    font-weight: 500;
    padding: 0.5rem 0.5rem !important;
    height: 2.5rem !important;
    border-radius: 0.25rem !important;

    &:hover {
        border-color: #2196f3;
    }

    &::placeholder {
        color: var(--gray-500) !important;
    }

    &:focus {
        box-shadow: 0 0 0 1px var(--primary);
    }
}

.text-wrap {
    text-wrap: wrap !important;
}

.logo {
    color: var(--white);
    font-weight: 600;

    &.dark-blue {
        color: var(--dark-blue);
    }

    span {
        color: var(--primary);
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--gray-50);
}

.bg-primary {
    background-color: var(--primary) !important;
}

// Autocomplete component from primng

.p-autocomplete {
    width: 100% !important;
}

:host ::ng-deep .p-autocomplete-multiple-container {
    width: 100%;
}

:host ::ng-deep .autocomplete {
    width: 100%;
    font-size: 0.875rem !important;
    font-weight: 500;
    color: var(--dark-blue) !important;
    font-family: 'inter', sans-serif !important;
    height: 2.5rem;

    &:focus {
        box-shadow: 0 0 0 1px var(--primary);
    }

    &::placeholder {
        color: var(--gray-500);
    }

    &.autocompleteMultipleInput {
        padding: 0.25rem !important;

        &:focus {
            box-shadow: none;
        }
    }
}

:host ::ng-deep .p-autocomplete-input-token {
    padding: 0 !important;
}

:host ::ng-deep .p-autocomplete-multiple-container {
    padding: 0 0.25rem;

    input {
        padding-left: 0;
    }

    &:focus-within {
        box-shadow: 0 0 0 1px var(--primary) !important;
    }
}

.content {
    overflow: hidden;

    .navbar-brand {
        text-align: center;
        width: 100%;
        background-color: var(--dark-blue);
    }
}

.point {
    cursor: pointer;
}

blockquote {
    background: var(--gray-100);
    border-left: 4px solid var(--gray-300);
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: '\201C' '\201D' '\2018' '\2019';
    border-radius: 6px;
}

th[required] {
    &::after {
        content: '*';
        color: red;
        margin-left: 4px;
    }
}

.invalid-input {
    border: 1px solid #fa2d2d !important;
    box-shadow: 0 0 0 1px #fa2d2d !important;
}


.chip {
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    border-radius: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;

    button {
        border: none;
        background: transparent;
        color: transparent;
        position: absolute;
        top: 50%;
        right: 0.15rem;
        transform: translateY(-50%);

        i {
            font-family: fontawesome;
            cursor: pointer;
            color: var(--primary);
        }
    }

    .limit {
        float: right;
    }

}

.chip-primary {
    background: var(--secondary);
    color: var(--primary);
}

.chip-danger {
    background: #EE0000;
    color: white;
}

.chip-medium {
    background: #FFD643;
    color: white;
}

.chip-success {
    background: #86DD5D;
    color: white;
}

.chip-warning {
    background: #FF7A00;
    color: white;
}

.text-sefin {
    width: 100%;
    color: #0a2156;
    background-color: rgb(244, 242, 255);
    font-size: 0.75rem !important;
}

.btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    gap: 0.5rem;
    box-shadow: none !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 0.25rem;

    transition: background-color .125s ease-in-out,
    color .125s ease-in-out i {
        font-size: 1rem;
    }
}

.btn .btn-primary:active {
    background-color: #003793 !important;
}

.btn .btn-primary:hover {
    background-color: #0952cc !important;
}

.btn-secondary {
    background-color: var(--white) !important;
    border: 1.5px solid var(--gray-200) !important;
    color: var(--dark-blue) !important;
    transition: .4s ease;
}

.btn-secondary:hover {
    background-color: var(--primary-50) !important;
}

.border-1 {
    border-width: 2px !important;
}

.badge {
    text-transform: none !important;
    font-weight: 500 !important;
}

.bg-success-light {
    color: #2dce89 !important;
    background-color: #2dce8816;
    border: 1px solid #2dce89;
}

.dot {
    content: '\2022';
    min-width: 0.25rem;
    min-height: 0.25rem;
    background-color: var(--gray-400);
    border-radius: 999rem;
}

.bg-evaluation {
    background-color: var(--evaluation) !important;
}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
  
.rotate-360 {
    animation: rotate360 1s linear infinite;
}

.custom-dropdown {
    width: 100%;
    height: 40px;
    font-size: 16px;
}

.custom-dropdown .p-dropdown-trigger {
    width: 40px;
    height: 40px;
}

.custom-dropdown .p-dropdown-trigger-icon {
    font-size: 10px;
}

.filter-modal {
    max-height: 80vh;
    overflow-y: auto;
    padding: 1rem;
    background: white;
    border-radius: 8px;
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.filter-header .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.filter-header h1 {
  font-size: 1.5rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.filter-body .btn {
  flex-grow: 1;
  min-width: 120px;
}

@media (max-width: 768px) {
    .filter-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
  
    .filter-header .badge {
      width: 35px;
      height: 35px;
    }
  
    .filter-header h1 {
      font-size: 1.2rem;
    }
  
    .filter-body .btn {
      width: 100%;
      margin-top: 10px;
    }
}