/* You can add global styles to this file, and also import other style files */

/* modal animation */
.modal-content {
    animation-name: example;
    animation-duration: 0.2s;
}

@keyframes example {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
    }
}

.bg-gerinus-argon {
    background-color: var(--primary-color);
}

.footer {
    a {
        color: var(--primary-color) !important;

        &:hover {
            color: var(--primary-color) !important;
            opacity: 0.9;
        }

        &:active {
            color: var(--primary-color) !important;
            opacity: 0.9;
        }
    }
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.dropdown-item,
a.dropdown-item {
    &:active {
        background-color: var(--primary-color);
        color: #fff;
    }
}

.btn {
    border-radius: 0.375rem !important;
}

.btn-primary {
    background-color: var(--primary-color);

    &:hover {
        background-color: var(--primary-color);
    }
}

.text-primary,
a.text-primary {
    color: var(--primary-color) !important;

    &:hover {
        color: var(--primary-color) !important;
        opacity: 0.8 !important;
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
}

.navbar-main {
    a {
        color: #fff !important;
    }
}

gerinus-cad {
    thead {
        background-color: white;
    }

    td {
        vertical-align: middle !important;
    }
}

.w-5 {
    width: 5% !important;
}
.w-10 {
    width: 10% !important;
}
.w-15 {
    width: 15% !important;
}
.w-20 {
    width: 20% !important;
}
.w-25 {
    width: 25% !important;
}
.w-30 {
    width: 30% !important;
}
.w-33 {
    width: 33.3333% !important;
}
.w-35 {
    width: 35% !important;
}
.w-40 {
    width: 40% !important;
}
.w-45 {
    width: 45% !important;
}
.w-50 {
    width: 50% !important;
}
.w-55 {
    width: 55% !important;
}
.w-60 {
    width: 60% !important;
}
.w-67 {
    width: 66.6667% !important;
}
.w-65 {
    width: 65% !important;
}
.w-70 {
    width: 70% !important;
}
.w-75 {
    width: 75% !important;
}
.w-80 {
    width: 80% !important;
}
.w-85 {
    width: 85% !important;
}
.w-90 {
    width: 90% !important;
}
.w-95 {
    width: 95% !important;
}
.w-100 {
    width: 100% !important;
}
